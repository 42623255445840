import CONSTANTS from "@/util/constants";
import { createRouter, createWebHashHistory } from "vue-router";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: '/', name: 'home', component: () => import('../pages/home') },
    { path: '/about', name: 'about', component: () => import('../pages/about') },
    { path: '/news/:code', name: 'news-detail', component: () => import('../pages/newsDetail') },
    { path: '/example/:code', name: 'example-detail', component: () => import('../pages/exampleDetail') },
    { path: '/recruit', name: 'recruit', component: () => import('../pages/recruit') },
    { path: '/contact', name: 'contact', component: () => import('../pages/contact') },
    { path: '/solution/:code', 
      name: '/solution', 
      component: () => import('../pages/solution'),
      redirect: ''
    },
    { path: '/company', name: 'company', component: () => import('../pages/company') },
    { 
      path: '/login', 
      name: 'login', 
      component: () => import('../pages/login'),
      beforeEnter: async (to, from, next) => {
        const userInfo = localStorage.getItem(CONSTANTS.USER_INFO);
        if (userInfo) {
          next({ name: 'salary' })
        } else {
          next()
        }
      } 
    },
    { 
      path: '/salary', 
      name: 'salary', 
      component: () => import('../pages/salary'), 
      redirect: '/salary/management',
      children: [
        { path: '/salary/management', name: 'management', component: () => import('../pages/salary/management') },
        { path: '/salary/record', name: 'record', component: () => import('../pages/salary/record') },
        { path: '/salary/history', name: 'history', component: () => import('../pages/salary/history') },
      ],
      beforeEnter: async (to, from, next) => {
        const token = localStorage.getItem(CONSTANTS.USER_INFO);
        if (token) {
          next()
        } else {
          next({ name: 'login' })
        }
      } 
    },
    {
      path: '/:catchAll(.*)',
      component: () => import('../pages/notFound')
    }
  ]
})

router.afterEach((to, from) => {
  if (to.name != '/solution' || from.name != '/solution') {
    document.body.scrollIntoView()
  }
})

export default router;
