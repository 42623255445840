
import { defineStore } from 'pinia'

export const usePageStore = defineStore('pageStore', {
    state: () => {
        return {
            companyAnchor: null
        }
    },
    actions: {
        setCompanyAnchor (companyAnchor) {
            this.companyAnchor = companyAnchor;
        },
    }
})

export default usePageStore;
