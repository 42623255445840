import Axios from "axios";
import CONSTANTS from "./constants";
import router from "@/router";
import useUserStore from "@/store/userStore";

const axios = Axios.create({
  timeout: 30000,
  headers: { "X-Custom-Header": "foobar" },
  baseURL: process.env.VUE_APP_ENTERPRISE_URL,
});

// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem(CONSTANTS.TOKEN);
    config.headers.Authorization = "Bearer " + token;
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  async (response) => {
    if (response.status == 200) {
      if (response.data.code === 401) {
        const userStore = useUserStore();
        userStore.clearStorage();
        router.replace("/login");
      }
      return response.data;
    } else {
      return response.data;
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axios;
