<template>
  <TopBar v-if="userStore.userInfo" />
  <Header />
  <router-view></router-view>
  <Feader />
  <SideBar />
</template>

<script setup>
import TopBar from './components/topBar';
import Header from './components/header';
import Feader from './components/footer';
import SideBar from './components/sideBar';
import useUserStore from '@/store/userStore';
const userStore = useUserStore();

</script>

<style>
#app {
  margin-top: 74px;
}

</style>