import CONSTANTS from "./constants";


const util = {};

util.classifyArr = (arr, num) => {
  const _arr = [...arr];
  const lines = [];
  while (_arr.length) {
    const line = [];
    lines.push(line);
    while (line.length < num && _arr.length) {
      const item = _arr.shift();
      line.push(item);
    }
  }
  return lines;
};

util.copyTextToClipboard = (text) => {
  var textArea = document.createElement("textarea");

  // 隐藏文本域
  textArea.style.position = "fixed";
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.width = "2em";
  textArea.style.height = "2em";
  textArea.style.padding = "0";
  textArea.style.border = "none";
  textArea.style.outline = "none";
  textArea.style.boxShadow = "none";
  textArea.style.background = "transparent";
  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Copy text command was " + msg);
  } catch (err) {
    console.error("Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
};

// 是否是管理员
util.isAdmin = () => {
  const userInfoStr = localStorage.getItem(CONSTANTS.USER_INFO);
  const userInfo = JSON.parse(userInfoStr);
  const isAdmin = userInfo.permissions.includes('*:*:*');
  return isAdmin;
};

util.initChart = (chart, { legendData, seriesData, name, title }) => {
  chart.setOption({
    title: {
      text: title,
      bottom: 0,
      left: "center",
      textStyle: {
        color: "#fff",
      },
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      right: 10,
      top: 50,
      data: legendData,
      textStyle: {
        color: "#fff",
      },
    },
    series: [
      {
        name: name,
        type: "pie",
        radius: "55%",
        center: ["40%", "50%"],
        data: seriesData,
        label: {
          showLine: false,
          show: false,
        },
        emphasis: {
          label: {
            showLine: false,
          },
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  });
};

/**
 * 下载函数
 * @param {string} data - 后端获取的文件流
 * @param {string} name - 文件名
 */
util.downloadFile = (data, name) => {
  const blob = new Blob([data]);
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.style.display = "none";
  a.click();
  document.body.removeChild(a);
}


export default util;
