import {
  loginApi,
  getLoginInfoApi,
  loginOutApi,
  getCaptchaInfoApi,
} from "@/api";
import { defineStore } from "pinia";
import CONSTANTS from "@/util/constants";

export const useUserStore = defineStore("userInfo", {
  state: () => {
    return {
      userInfo: JSON.parse(localStorage.getItem(CONSTANTS.USER_INFO)) || null,
      checkCode: {
        img: "",
        uuid: "",
      },
    };
  },
  actions: {
    // 登录
    async loginIn(params) {
      const resp = await loginApi(params);
      if (resp.code === 200) {
        localStorage.setItem(CONSTANTS.TOKEN, resp.token);
        const resp2 = await getLoginInfoApi();
        if (resp2.code == 200) {
          const userInfo = resp2.data;
          this.userInfo = userInfo;
          localStorage.setItem(CONSTANTS.USER_INFO, JSON.stringify(userInfo));
          return resp2;
        }
      } else {
        return resp;
      }
    },
    // 设置验证码
    async setCaptchaInfo() {
      const resp = await getCaptchaInfoApi();
      if (resp.code === 200) {
        this.checkCode.img = resp.img;
        this.checkCode.uuid = resp.uuid;
      }
    },
		// 清空缓存
    clearStorage() {
      localStorage.removeItem(CONSTANTS.USER_INFO);
      localStorage.removeItem(CONSTANTS.TOKEN);
      this.userInfo = null;
    },
    // 登出
    async loginOut() {
      await loginOutApi();
      this.clearStorage();
    },
  },
});

export default useUserStore;
