<template>
  <i class="iconfont" :class="[props.icon, ...clzs]" :style="{ color: props.color }"></i>
</template>

<script setup>
import { computed, defineProps } from 'vue';
const props = defineProps({
  icon: {
    type: String
  },
  size: {
    type: String,
    default: '14'
  },
  color: {
    type: String,
    default: '#999'
  },
  bgStyle: {
    type: String,
    default: null
  }
})

const clzs = computed(() => {
  return props.bgStyle ? ['bg-gradient', props.bgStyle]: [];
})

</script>

<style lang="scss" scoped>
.bg-gradient {
  color: transparent!important;
  background-clip: text!important;
  &.blue {
    background: linear-gradient( 180deg, #7EE8FF, #2670FF);
  }
  &.purple {
    background: linear-gradient( 180deg, #C4A7F4, #C065E0);
  }
  &.dark-purple {
    background: linear-gradient( 180deg, #B9B0F4, #5654BE);
  }
  &.yellow {
    background: linear-gradient( 180deg, #F8E375, #FA9A29);
  }
  &.green {
    background: linear-gradient( 180deg, #CCF772, #42BF5E);
  }
  &.red {
    background: linear-gradient( 180deg, #FF7A00, #FF0F00);
  }
}

</style>