<template>
	<div class="top-bar-wrap">
		<div class="center-content top-bar-container">
			<span></span>
			<div class="user-info-container">
				<el-avatar :size="24" :src="userStore.userInfo.sysUser.avatar" />
				<div class="user-name">{{ userStore.userInfo.username }}</div>
				<el-dropdown>
					<span class="drop-down-container">
						&gt;
					</span>
					<template #dropdown>
						<el-dropdown-menu>
							<!-- <el-dropdown-item @click="checkUser">切换用户</el-dropdown-item> -->
							<el-dropdown-item @click="loginOut">登出</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>

		</div>
	</div>
</template>

<script setup>
import useUserStore from '@/store/userStore';
import { useRouter } from 'vue-router';
const userStore = useUserStore();
const router = useRouter();

const checkUser = () => {
	router.replace('/login')
}
const loginOut = async () => {
	await userStore.loginOut();
	router.replace('/login')
}


</script>

<style lang="scss" scoped>
.top-bar-wrap {
	background-color: #F5F7FA;
}

.top-bar-container {
	height: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.user-info-container {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.avatar {}

	.user-name {
		margin-left: 8px;
	}

	.drop-down-container {
		margin-left: 10px;
		color: #333;
		font-family: fangsong;
		transform: rotate(90deg);
		font-weight: bold;
	}
}
</style>