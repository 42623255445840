import axios from "@/util/request";

// 登录
export const loginApi = (params) => {
  return axios.post("rcgj2b/release/user/loginUR", params);
};

// 获取验证码
export const getCaptchaInfoApi = (params) => {
  return axios.get("rcgj2b/release/user/getCaptchaInfo", params);
};

// 获取验证码
export const exportSalaryFormTemplateApi = () => {
  return axios.get("rcgj2b/salary/exportSalaryFormTemplate", {
    responseType: "blob",
  });
};

// 获取用户信息
export const getLoginInfoApi = () => {
  return axios.get("rcgj2b/bro/user/loginInfo");
};

// 退出登录
export const loginOutApi = () => {
  return axios.put("rcgj2b/bro/user/loginOut");
};

// 分页查询工资单
export const selectSalaryFormApi = (params) => {
  return axios.get("rcgj2b/salary/selectSalaryForm", {
    params,
  });
};

// 判断工资单是否存在
export const checkSalaryExistApi = (params) => {
  return axios.get("rcgj2b/salary/checkSalaryExist", {
    params,
  });
};

// 判断工资单内容检查
export const excelImportApi = (params) => {
  return axios.post("rcgj2b/salary/excelImport", params);
};

// 工资单详情
export const selectSalaryFormDetailApi = (params) => {
  return axios.get("rcgj2b/salary/selectSalaryFormDetail", {
    params,
  });
};

// 工资单提交审批
export const submitSalaryFormByIdApi = (id) => {
  return axios.put("rcgj2b/salary/submitSalaryForm/" + id);
};

// 删除工资单
export const deleteFormByIdApi = (id) => {
  return axios.delete("rcgj2b/salary/deleteFormById/" + id);
};

// 工资单审批
export const confirmSalaryFormByIdApi = (id) => {
  return axios.put("rcgj2b/salary/confirmSalaryForm/" + id);
};

// 工资单驳回
export const rejectSalaryFormByIdApi = (id, params) => {
  return axios.put("rcgj2b/salary/rejectSalaryForm/" + id, params);
};
