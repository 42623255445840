<template>
    <div class="side-bar-container">
        <div class="wx-icon side-bar-item side-bar-style icon-size-1" @mouseenter="shareMouseenter" @mouseleave="shareMouseleave">
            <Iconfont icon="icon-weixin" color="#09BB07"></Iconfont>
            <transition name="el-fade-in">
              <div v-show="visible" class="code-box">
                <img src="../../assets/imgs/wx.png" alt="">
              </div>
            </transition>
        </div>
        <div class="tel-icon side-bar-item side-bar-style text-3 icon-size-1">
            <span class="visible num">{{ info.tel }}</span>
            <span class="visible copy" @click="copy(info.tel)">复制</span>
            <el-divider class="visible" direction="vertical" />
            <Iconfont icon="icon-zuojidianhua" color="#216CE0"></Iconfont>
        </div>
        <div class="phone-icon side-bar-item side-bar-style text-3 icon-size-1">
            <span class="visible num">{{ info.phone }}</span>
            <span class="visible copy" @click="copy(info.phone)">复制</span>
            <el-divider class="visible" direction="vertical" />
            <Iconfont icon="icon-shoujihao" color="#FF8A00"></Iconfont>
        </div>
        <div class="warning-icon side-bar-item side-bar-style text-3 icon-size-1">
            <span class="visible num">投诉与建议请拨打：{{ info.warning }}</span>
            <span class="visible copy" @click="copy(info.warning)">复制</span>
            <el-divider class="visible" direction="vertical" />
            <Iconfont icon="icon-Frame" color="#FF4219"></Iconfont>
        </div>
    </div>
    <el-backtop :bottom="100" :right="16" class="side-bar-style custom-backtop">
        <span class="visible text-3">返回顶部</span>
        <el-divider class="visible" direction="vertical" />
        <Iconfont icon="icon-zhiding"></Iconfont>
    </el-backtop>
</template>

<script setup>
import { ref } from 'vue';
import Iconfont from '@/components/iconfont';
import util from '@/util';
const info = {
    phone: '18013555601',
    tel: '0512-65924413',
    warning: '18013555601',
}

const visible = ref(false)
const copy = (txt) => {
    util.copyTextToClipboard(txt)
}

const shareMouseenter = () => {
    visible.value = true;
}

const shareMouseleave = () => {
    visible.value = false;
}

</script>

<style lang="scss" scoped>

.custom-backtop {
    transition: all 0.3s;
    border-radius: 30px;
    font-size: 14px;
    .visible {
        transition: 0.3s;
        display: none;
        white-space: nowrap
    }
    &:hover {
        width: 153px;
        .visible {
            display: block;
        }
    }
}
.side-bar-style {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .12);
}
.side-bar-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    bottom: 176px;
    right: 16px;
    .side-bar-item {
        border-radius: 30px;
        background-color: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        transition: all 0.3s;
        position: relative;
        // overflow: hidden;
        & + .side-bar-item {
            margin-top: 16px;
        }
        .num {
            overflow: hidden;
            white-space: nowrap
        }
        .copy {
            margin-left: 12px;
            white-space: nowrap;
            color: #216CE0;
        }
    }
    .tel-icon, .phone-icon, .warning-icon {
        .visible {
            display: none;
        }
        &:hover {
            .visible {
                display: block;
            }
        }
    }
    .warning-icon {
        &:hover {
            width: 342px;
        }
    }
    .tel-icon {
        &:hover {
            width: 232px;
        }
    }
    .phone-icon {
        &:hover {
            width: 222px;
        }
    }
    .code-box {
        position: absolute;
        top: 0;
        right: 70px;
        img {
            width: 108px;
            height: 108px;
        }
    }
}
</style>